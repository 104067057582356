import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BreadCrumbs from "../components/breadCrumbs";
import { makeStyles } from "@material-ui/core";
import MembershipContent from "../components/membership/template/membershipContent";

const useStyles = makeStyles(({ theme }) => ({
  root: {
    width: "100%",
    marginTop: "4.166vw",
    padding: "0 3.47vw",
    "@media(min-width: 1440px)": {
      padding: "0 50px",
      marginTop: 60,
    },
    "@media(max-width: 767px)": {
      padding: 0,
      marginTop: "4.83vw",
    },
  },
}));

const MembershipFitnessPage = ({ data: { prismicMembershipFitness } }) => {
  const classes = useStyles();
  const fitnessData = prismicMembershipFitness.data;
  return (
    <Layout>
      <Seo title="Абонемент" />
      <BreadCrumbs
        links={[
          { title: "Абонементы" },
          {
            title: fitnessData.name,
            href: `/memberships/${prismicMembershipFitness.uid}`,
          },
        ]}
      />

      <div className={classes.root}>
        <MembershipContent data={fitnessData} type={"fitness"} />
      </div>
    </Layout>
  );
};

/**
 * Страница Абонемента
 * @module src/templates/membership
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 */
export default MembershipFitnessPage;

export const query = graphql`
  query MembershipFitnessPage($uid: String!) {
    prismicMembershipFitness(uid: { eq: $uid }) {
      uid
      data {
        addresses {
          address {
            document {
              ... on PrismicAddress {
                data {
                  name
                }
              }
            }
          }
        }
        name
        price
        tag_age
        tag_category
        tag_type
        time
        trained_by
        images {
          image {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
